import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Player, BigPlayButton } from 'video-react';
import SingleVideoPopup from './modules/components/company/SingleVideoPopup';
import Box from '@material-ui/core/Box';
import Person from '@material-ui/icons/Person'
import IconButton from '@material-ui/core/IconButton';
import { Textfit } from 'react-textfit'
import QRCodeResized from './modules/components/company/QRCodeResized';



const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});




export default function Jobprofile(props) {
  const classes = useStyles();

  return (
    <div>


      <span className="card-1__symbol mx-auto mb-4">
        <i className="fas fa-video"></i>
      </span>

      <Button onClick={() => props.callback("jobprofile")}>
        <img src={props.video.videoThumbLow} width={"100%"} />
      </Button>
      <h3 className="card-1__title mb-2">{props.jobname} </h3>
      {
        props.jobdata.salary && props.jobdata.salary !== "0" ? 
      
      <p style={{fontSize: "10pt"}}>{props.jobdata.salary + props.jobdata.currency+'/' + props.jobdata.salaryUnit}  </p>
      : null
}
      <p className="card-1__paragraph">
        {props.subtitle}
      </p>

      {
        props.id ? 
        <QRCodeResized size={640} information={"https://application.allesjob.at/?profileid=" + props.id  } level='Q' buttonLabel={"Videobewerbung"} />
        : null
      }
      {console.log("QRLINK: " + props.jobname +  " https://application.allesjob.at/?profileid=" + props.id  
        
        + props.greetingsurl)}


      {
        props.contact ?

          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

            <IconButton aria-label="next">
              <Person onClick={() => props.callback("contact")} />


            </IconButton>
          </Box>
          : null
      }




    </div>

  );

}


/*

export default function Jobprofile(props) {
  const classes = useStyles();

  return (

    <Card className={classes.root}>
      <CardActionArea>


        <img onClick={() => props.callback("jobprofile")} src={props.video.videoThumbLow} width={"100%"}/>


        <CardContent>

      <Textfit mode="single" max="30" >
          {props.jobname}
      </Textfit>

      <Textfit mode="multi">
      {props.jobdesc}
      </Textfit>





        </CardContent>

{
  props.contact ?

        <Box sx={{  display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>

          <IconButton  aria-label="next">
            <Person onClick={ () => props.callback("contact") } />


          </IconButton>
        </Box>
        : null
}
      </CardActionArea>
      <CardActions>
      </CardActions>
    </Card>
  );
}

*/
