module.exports = {

  api_base_path: 'https://allesjob.at', // beim GoLive
  portalpath: '', //GoLive

  
//  api_base_path: 'https://api2-demo.joobster.at', // beim Entwickeln setzen!
 // portalpath: '/portal', // nur für Entwicklung

//  mode: "dev"
  mode: "live"
  
 
}
