
import React, { Component } from 'react'
import { Player, BigPlayButton, ControlBar } from 'video-react';
import Jobprofile from '../../../Jobprofile'

import {Card, CardBody, CardHeader, Row, Col, Label, Tooltip, Form, FormGroup, FormText, Alert, View, InputGroup, InputGroupText, InputGroupAddon, InputGroupButton, Input, Fade, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next'
import '../../../i18n'
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import ReactHtmlParser from 'react-html-parser'; 

//import { CardHeader } from 'material-ui';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  rootIndicator: {
    flexGrow: 1,
  },
  chip: {
    margin: theme.spacing.unit,
  },

  fab: {
    margin: theme.spacing.unit,
    display: 'flex',
    //marginRight: "auto" ,
    //justifyContent: 'flex-end',
    // position: 'absolute',
    // right: '0',
    //float: 'center',
    background: 'yellow',
    height: "36px",
    fontFamily: "Roboto, sans-serif",
    borderRadius: "0px",
    textTransform: 'capitalize',
    justifyContent: 'center'


  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});


class SingleVideoUpdate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      type: "jobprofile" //standard
    };


    this.toggle = this.toggle.bind(this);
  }



  async toggle(type, e) {


    if (!this.state.modal) {
      console.debug("value of video is: ", this.props.video)
      this.setState({
        type
      })
    }
    this.setState({
      modal: !this.state.modal
    });


  }



  render() {

    var { classes, t, video } = this.props

    let employmenttypes = [
      { id: "FULL_TIME", name: t('job.employmenttype.fulltime')},
      { id: "PART_TIME", name: t('job.employmenttype.parttime')},
      { id: "CONTRACTOR", name: t('job.employmenttype.contractor')},
      { id: "TEMPORARY", name: t('job.employmenttype.temporary')},
      { id: "INTERN", name: t('job.employmenttype.intern')},
      { id: "VOLUNTEER", name: t('job.employmenttype.volunteer')},
      { id: "PER_DIEM", name: t('job.employmenttype.perdiem')},
      { id: "OTHER", name: t('job.employmenttype.other')},
    ]
    let monthtypes = [{ id: "HOUR", name: t('job.salaryunit.hour')}, { id: "DAY", name: t('job.salaryunit.day')}, { id: "WEEK", name: t('job.salaryunit.week')}, { id: "MONTH", name: t('job.salaryunit.month')}, { id: "YEAR", name: t('job.salaryunit.year')}]

    let type = video.employmentType ? employmenttypes.find(e => e.id===video.employmentType) : ''
    let mtype = video.salaryUnit ? monthtypes.find(m => m.id === video.salaryUnit) : ''
    let titletype = type && type!== undefined ? type.name : ''
    let salarytitle = mtype && mtype!== undefined ? mtype.name : ''


    return (





      <div className={this.props.className} >
        <div className="card-1 card-1--selected text-center">
          <Jobprofile 
          callback={(type) => this.toggle(type)}
          contact={this.props.contact}
          video={this.props.video.video}
          jobname={this.props.video.title}
          subtitle={this.props.video.subtitle} 
          id={this.props.id}
          jobdata={{
          applicationUrl: video.applicationUrl,
          description: video.description, 
          publishDate: video.publishDate,
          publishUrl: video.publishUrl,
          salary: video.salary, 
          salaryUnit:salarytitle, 
          employmentType: titletype, 
          currency: video.currency,
          
          }
        }
         // greetingsurl= { this.props.hasOwnProperty("contact") && this.props.contact!==null && this.props.contact.hasOwnProperty("video") ?
         // "&greetingsurl="+this.props.contact.video.videoMedium : ""}          
          

                    />




          <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" className={this.props.className}>
            <ModalHeader toggle={this.toggle}>{this.props.video.title}</ModalHeader>
            <ModalBody>

              {
                this.state.type === "contact" ?
                  <Player
                    poster={this.props.contact.video.videoThumbLow} src={this.props.contact.video.video}
                    autoPlay
                  >

                  </Player>
                  : this.props.type === "contact" ? 

                  <Player
                  poster={this.props.video.video.videoThumbLow} src={this.props.video.video.video}
                  autoPlay
                />   
                  :
<>
                  <Player
                    poster={this.props.video.video.videoThumbLow} src={this.props.video.video.video}
                    autoPlay
                  />
                  <br/>
                  <Card>
                  <CardHeader style={{color:"#000"}}>{t('job.jobattributes.title')}</CardHeader>
                    <CardBody style={{color:"#000", paddingLeft: "5%" }}>
                     
                      {video.title }
                      
                    </CardBody>
                  </Card>                  
                  <Card>
                  <CardHeader style={{color:"#000"}}>{t("job.jobattributes.salary")}</CardHeader>
                    <CardBody style={{color:"#000", paddingLeft: "5%"}}>
                    
                      {video.salary + video.currency +'/'+salarytitle }
                    
                    </CardBody>
                  </Card>                  
                  <Card>
                  <CardHeader style={{color:"#000"}}>{t("job.jobattributes.applicationurl")}</CardHeader>
                    <CardBody style={{color:"#000", paddingLeft: "5%"}}>
                    
                      <a href={video.applicationUrl} target="_blank" > {video.applicationUrl} </a> 
                    
                    </CardBody>
                  </Card>
                  <Card>
                  <CardHeader style={{color:"#000"}}>{t('job.employmenttype.dropdowntext')}</CardHeader>
                    <CardBody style={{color:"#000", paddingLeft: "5%"}}>
                    
                      {titletype }
                      
                    </CardBody>
                  </Card>
                  <Card>
                  <CardHeader style={{color:"#000"}}>{t('job.jobattributes.date')}</CardHeader>
                    <CardBody style={{color:"#000", paddingLeft: "5%"}}>
                    
                      {video.publishDate }
                    
                    </CardBody>

                  </Card> 
                  <Card>
                    
                  <CardHeader style={{color:"#000"}}>{t('job.label.urlcarrier')}</CardHeader>
                    <CardBody style={{color:"#000", paddingLeft: "5%"}}>
                    
                      <a href={video.publishUrl} target="_blank"  >{video.publishUrl}</a>
                    
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader style={{color:"#000"}}>{t('job.jobdescription')}</CardHeader>
                    <CardBody>
                      {ReactHtmlParser( video.description) }
                    </CardBody>
                  </Card>
                  </>

              }
              

            </ModalBody>

            <ModalFooter>
              <Button color="primary" onClick={() => this.toggle()}>{t('general.closetext')}</Button>
            </ModalFooter>

          </Modal>
        </div>
      </div>

    );
  }


}

export default withTranslation()(withStyles(styles)(SingleVideoUpdate));

