import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MovieList from './movie-list/movie-list.component';
//import * as scrollHelpers from '../common/scroll.helpers';
//import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Grid2 from '@material-ui/core/Grid';
import MovieSections from './movieSections';
//import logo from './images/joobster.jpg';
import SearchButton from '../components/SearchButton';
//import CarouselStartPage from '../components/webhtml/CarouselStartPage';
import "../components/webhtml/CarouselStyle.css";
import "react-multi-carousel/lib/styles.css";
//import Header from '../components/webhtml/Header'
import { connect } from 'react-redux';
import * as action from '../../redux/action'
//import { CircularProgress, CardHeader, Card } from 'material-ui';
//import { Link } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-custom-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter, Comparator } from 'react-bootstrap-table2-filter';
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
//import { Player, BigPlayButton } from 'video-react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
//import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MyCarousel from '../../modules/components/webhtml/MyCarousel'
import { Jumbotron, Container as ContainerReactstrap } from 'reactstrap';
import settings from '../../settings'
//import Chip from '@material-ui/core/Chip';
//import VideoKeywordBrowser from './VideoKeywordBrowser';
import { withTranslation } from 'react-i18next';
import * as router from 'react-router-dom';
import jobpic from '../../public/img/jobbild.jpg'
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import './sliderstyles.css'
//import './slider-animations.css'



const styles = theme => ({
  root: {
    width: '100%',

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular

  },

  column: {
    flexBasis: '45%'
  },

  margin: {
    margin: theme.spacing(2),

  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  button: {
    margin: theme.spacing.unit,

  },

  center: {
    margin: 'auto'

  }


});



class MovieBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      domain: null,
      searchTextField: '',
      topMovies: [],
      sections: [],
      firstTime: true,
      choosencategorievalue: [],
      topmoviescallback: null,
      choosenidvalue: null,
      view: 0, // if view = 0 , standardview is enabled, if view = 1, tableview is shown
      choosenElem: {} // is the callback-Value-Object from SearchDropDown
    };


    //this.handleScroll = this.handleScroll.bind(this);
    this.searchFieldClicked = this.searchFieldClicked.bind(this);
    this.setTextField = this.setTextField.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.exchangeFunc = this.exchangeFunc.bind(this);
    this.movielistCallback = this.movielistCallback.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  /*
  
    getTopMovies = async () => {
      //const retval = await fetch('https://api2.joobster.at/portal/group/5b4ea9901bb6981a06c5be80/clip');
      const retval = await fetch('portal/group/5b4ea9901bb6981a06c5be80/clip');
      //const retval = await fetch('https://portal.joobster.at/portal/group/5b4ea9901bb6981a06c5be80/clip');
      return retval.json();
      //return fetch('https://portal.joobster.at/portal/group/5b4ea9901bb6981a06c5be80/clip'); 
    }
  */


  handleChange = (e, view) => {
    console.log('view is: ', view)
    this.setState({ view: view })
  }
  async componentDidMount() {


    const videoresponse = { clips: this.props.clips } //{ clips: this.props.clips}

    //let domain = this.getUrlVars()["domain"]
    //let domain = window.location.hostname
    let domain = settings.mode === "dev" ? "portal.joobster.at" : window.location.hostname

    this.setState({ domain })
    //check if weburl contains parameters
    var CATUrlParameterValue = this.getUrlVars()["cat"];
    var IDUrlParameterValue = null
    var LangUrlParameterValue = this.getUrlVars()["lang"];
    if (CATUrlParameterValue) {
      this.setState({ choosencategorievalue: CATUrlParameterValue.split(",") });
      console.log("urlparams cat given", CATUrlParameterValue.split(","))
    } else if (IDUrlParameterValue = this.getUrlVars()["id"]) {
      this.setState({ choosenidvalue: IDUrlParameterValue });
      let sections = videoresponse.clips.filter(item => item.id == IDUrlParameterValue)[0].sections;
      console.log("sections FOR CLIPID are: ", sections);
      this.setState({ choosencategorievalue: sections });
    }else if (LangUrlParameterValue){
      this.props.i18n.changeLanguage(LangUrlParameterValue)
    }

    else console.log("no urlparams given")


    const prepCats = await this.preparesections(this.props.clips);
    //const sfclicked = await this.searchFieldClicked();


  }


  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  /*
    async tellMeIfMoviesAreThere() {
      return await this.state.topMovies.response.clips.length > 0;
    }
    */


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  /*
  handleScroll() {
    const { topMovies } = this.props;
    // const {movieSearch} = this.props;

    if (!topMovies.isLoading) {
      let percentageScrolled = scrollHelpers.getPercentageScrolledDown(window);
      if (percentageScrolled > .8) {
        const nextPage = this.state.currentPage + 1;
        // this.props.getTopMovies(nextPage);
        this.props.getTopMovies();
        this.setState({ currentPage: nextPage });
      }
    }
  }
  */

  setTextField(e) {
    this.setState({ searchTextField: e.target.value });
  }


  isClipCategoryInPortalCategory(cat) {

    // 2 sections: the clipsections and the choosen portal sections
    // cat = clipsections
    // this.state.sections = general all available portalsections | if no categorie in the portal has been choosen=empty field then search for all sections
    // this.state.choosencategorievalue = selected sections in portal



    if (cat == null || cat.length == 0) {
      return false;
    }



    if (this.state.choosencategorievalue.length == 0) {
      var arrayLength = cat.length;
      console.log("case 2 with emtpy choosencategorievalues");
      for (var i = 0; i < arrayLength; i++) { //loop over clipsections
        if (this.state.sections.includes(cat[i])) {
          return true;

        }
      }
    }
    else {

      var helpcategoriearray = this.state.choosencategorievalue;
      var arrayLength = cat.length;
      for (var i = 0; i < arrayLength; i++) { //loop over clipsections
        if (helpcategoriearray.includes(cat[i])) {
          return true;

        }
      }

    }

  }


  async searchFieldClicked(e) {

    if (this.props.clips) {

      var topmovies = this.props.clips //this.state.topMovies;
      var found = this.props.clips //this.state.topMovies;

      var retfound = topmovies.filter(element => {

        return (

          element.title.toUpperCase().includes(this.state.searchTextField.toUpperCase()) //&& this.isClipCategoryInPortalCategory(element.sections)

        );
      })

      if (retfound) found = retfound

      topmovies = retfound;


      this.props.setSearchRequest({ clips: topmovies })
    }

  }


  handleKeyPress(event) {
    if (event.key == 'Enter') {
      this.searchFieldClicked();
    }
  }


  async exchangeFunc(sections) {
    await this.setState({ choosencategorievalue: sections });
    console.log("IN EXCHANGEFUNC WHEN DROPDOWN CHANGED: ", sections)
    var retclips = await this.getClipsForSection(sections)
    this.searchFieldClicked();
  }



  getClipsForSection = async (sec) => {

    var retval = null;
    console.log("section choosed is: ", sec.join())

    let domain = this.state.domain // window.location.hostname


    if (sec && sec.length > 0) {

      if (this.props.auth && this.props.auth.hasOwnProperty('sessionId'))

        retval = await fetch(settings.api_base_path + settings.portalpath + '/clip?domain=' + domain + '&section=' + sec.join(),

          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.props.auth.sessionId
            }
          }
        );
      else retval = await fetch(settings.api_base_path + settings.portalpath + '/clip?domain=' + domain + '&section=' + sec.join());

      retval = await retval.json()
      console.log("getclipsforsection is: ", retval)
      this.props.setSearchRequest({ clips: retval.clips })
    }
    else {
      console.log('in getclipsforsection all cats are null')
      await this.props.getClipsRequest({ authToken: this.props.auth.sessionId })

    }


  }



  async preparesections(topmovies) {
    var sections = [];

    if (this.props.sections) {
      this.props.sections.map(section => {
        sections.push(section.name)
      })
    }
    this.setState({ sections: sections })
    return sections;

  }


  movielistCallback(paramobject) { // contains movie and movies; movie holds the movie to show and movies holds the available movies for the group
    console.log('Popup-Movie: ', paramobject)
    // this.props.history.push("/detail/?id="+movie.id)
    this.props.history.push(settings.api_base_path + settings.portalpath + "/detail/?id=" + paramobject.movieId)
    // return <Link to={"/detail/?id="+movie.id} />
  }

  render() {
    // const movies = this.state.topMovies;
    const movies = this.props.clips;
    const localthis = this
    const { t } = this.props


    function titelFormatter(cell, row, rowIndex, formatExtraData) {
      return (
        row.title
      );
    }

    function videoFormatter(cell, row, rowIndex, formatExtraData) {
      console.debug('row is: ', row)
      return (
        <div>
          {
            /*
          <Player playsInline preload={"none"} poster={row.video.videoThumbLow} src={row.video.videoLow} >
          <BigPlayButton position="center" />
            </Player>
    
    
            <VideoKeywordBrowser movie={row} movies={localthis.props.clips}  />
            */
          }
          {
            <img src={row.video.videoThumbLow}
              onClick={() => localthis.props.history.push(row.companyKey)} />


          }



        </div>
      );
    }


    function portalFormatter(cell, row, rowIndex, formatExtraData) {
      return (
        <div>
          {row.id}
        </div>
      );
    }

    /*
      function keywordFormatter(cell, row, rowIndex, formatExtraData) {
        return (
          <div>
            
     <VideoKeywordBrowser movie={row} movies={localthis.props.clips}  />
    
           </div>
        );
      }
    */

    const columns = [
      {
        dataField: 'title',
        text: t("clips.videotitle"),
        formatter: titelFormatter,
        filter: textFilter(),
        sort: true,

        headerStyle: (colum, colIndex) => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'video.videoThumbLow',
        text: t("clips.video"),
        formatter: videoFormatter,
        style: {
          width: '50%'
        }
      },
      /*
      {
        dataField: 'videoSearch',
        text: 'Keywörter',
        formatter: keywordFormatter,
        style: {
          width: '30%'
        }
      },
      */
      {
        dataField: 'id',
        text: t("clips.videodetails"),
        hidden: true,
        formatter: portalFormatter,
      },


      /*
          {
          dataField: 'video.video',
          text: 'QR-Code',
          formatter:  qrFormatter
            }
            */

    ];



    const selectRow = {
      mode: 'checkbox',
      classes: 'selection-row',
      onSelect: (row, isSelect, rowIndex, e) => {
      },
      onSelectAll: (isSelect, rows, e) => {
        console.log(isSelect);
        console.log(rows);
        console.log(e);
      }

    };

    function selectFormatter(column, colIndex, { sortElement, filterElement }) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {filterElement}
          {column.text}
          {sortElement}
        </div>
      );
    }


    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log(e + ' ' + row + ' ' + rowIndex);
      }
    }

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        &nbsp;	{t('clips.tablePaginationName')} {from} - {to} {t('clips.tablePaginationNameFrom')} {size}
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: t('general.tablefirstpagetext'),
      prePageText: t('general.tableprepagetext'),
      nextPageText: t('general.tablenextpagetext'),
      lastPageText: t('general.tablelastpagetext'),
      nextPageTitle: t('general.tablenextpagetitle'),
      prePageTitle: t('"general.tableprepagetitle'),
      firstPageTitle: t('general.tablefirstpagetitle'),
      lastPageTitle: t('general.tablelastpagetitle'),
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: this.props.clips.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };

    const content = [
      { title: t('movie-browser.container.sliderpic1.title'), image: jobpic, description: t('movie-browser.container.sliderpic1.desc') },
      // { title: 'Second item', button: 'next2', image: 'https://cdn.pixabay.com/photo/2021/07/19/15/47/lily-6478427_960_720.jpg', description: 'Lorem ipsum'}
    ];




    return (
      <div>

        <Slider className="slider-wrapper"

          autoplay={3000}>
          {content.map((item, index) => (
            <div
              key={index}
              className="slider-content"
              style={{ background: `url('${item.image}') no-repeat center center`, backgroundSize: "cover" }}
            >
          <div className="inner">
            <h1>{item.title}</h1>
            <p>{item.description}</p>
            
          </div>              
            </div>
          ))}
        </Slider>



        {
          this.props.advertisingclips && this.props.advertisingclips.length > 0 ? <div>
            <br />
            <MyCarousel
            />
          </div> : null
        }


        <Grid2 container className={styles.container} >
          <Grid2 item xs={12} >
            <Grid2 container spacing={2} alignitems="center" justify="center" direction="row" >

              <FormControl>


                {
                  // <SearchDropDown data={this.props.clips} badgeContent={movies.length} callback={(e) => this.choosenElement(e)} />
                }
                <SearchButton domain={this.state.domain} badgecolor={this.props.settings.secondaryColor} choosensections={this.state.choosencategorievalue} badgeContent={movies.length} />

                {

                  <MovieSections badgecolor={this.props.settings.secondaryColor} urlparams={

                    //catgiven? catgiven.split(",") : []
                    //this.getUrlVars()["cat"]?this.getUrlVars()["cat"].split(","):this.getUrlVars()["id"]?this.getCats(this.getUrlVars()["id"]):[]

                    //   this.getCats(this.getUrlVars())
                    null
                  }
                    exchangeFunc={this.exchangeFunc} />

                }
              </FormControl>
            </Grid2>
          </Grid2>
        </Grid2>

        {
          this.props.clips && this.props.clips.length > 0 ?
            <div>

              <br />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>

                <ToggleButtonGroup orientation="horizontal" value={this.state.view} exclusive onChange={this.handleChange}>
                  <ToggleButton value={0} aria-label="1">
                    <ViewModuleIcon />
                  </ToggleButton>
                  <ToggleButton value={1} aria-label="0">
                    <ViewListIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>




              <Container>
                {
                  this.state.view === 0 ?

                    <Row>

                      <MovieList movies={this.props.clips} mode={"popup"} callback={(browserobj) => this.movielistCallback(browserobj)} />


                    </Row>
                    :
                    <Row>
                      <BootstrapTable keyField='id'
                        //ref='table'
                        bootstrap4
                        data={this.props.clips}
                        wrapperClasses="table-responsive"
                        filter={filterFactory()}
                        filterPosition="top"
                        columns={columns}
                        //	selectRow={selectRow}
                        rowEvents={rowEvents}
                        pagination={paginationFactory(options)}
                      />
                    </Row>
                }
              </Container>


            </div> : <div>
              <Jumbotron >
                <ContainerReactstrap >
                  <h1 className="display-3">{t("clips.noclipsavailable")}</h1>
                  <p className="lead">{t("clips.nocontent")}</p>
                </ContainerReactstrap>
              </Jumbotron>
            </div>
        }

      </div>
    );


  }


}



const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    sections: state.clipReducer.sections,
    advertisingclips: state.clipReducer.advertisingclips,
    auth: state.authReducer.auth,
    settings: state.clipReducer.settings,
  }
};


const mapDispatchToProps = (dispatch) => ({
  getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)),
  setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))
});

export default withTranslation()(router.withRouter(connect(mapStateToProps, mapDispatchToProps)(MovieBrowser)))

