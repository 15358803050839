import React, { Component, Fragment, useParams } from 'react';
import MovieBrowser from './modules/movie-browser/movie-browser.container';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import * as action from './redux/action'
import { BrowserRouter, Route, Redirect , Link, Switch } from 'react-router-dom';
import Navigation from './modules/components/Navigation'; 
import Login from './modules/components/Login/Login'
import Footer from './modules/components/webhtml/Footer'
import MovieBrowserContainer from './modules/movie-browser/movie-browser.container'
import './i18n'
import {Button} from 'reactstrap'
import CompanyJobProfiles from './CompanyJobProfiles'
import Impressum from './modules/components/webhtml/Impressum'




class App extends Component {


  

  async componentDidMount() {
    
    await this.props.getClipsRequest({authToken: this.props.auth.sessionId})
  
  }


  render() {

    let localthis = this
    
/*
    function CompanyJobProfiles (props)  {
      // We can use the `useParams` hook here to access
     // the dynamic pieces of the URL.
     //let { id } = useParams();
     let id = props.match.params.id

     let companyinfo = localthis.props.clips.find(c => c.id === id)
    
     return (
       <div>
         <h3>ID: {companyinfo.title}</h3>
       </div>
     );
      
    };
    */


    return (

      
      <BrowserRouter>
    
        
          <Switch>

          <Route
            path="/impressum"
            render={(props) => <div>
              <Navigation />              
              <Impressum />
              <Footer   settings={{primaryColor: "#000"}}/>

            </div>}
            />

            <Route path="/:companyKey" children={ (props) =>  <CompanyJobProfiles {...props} />}></Route>


            <Route
            path="/"
            render={(props) =>
              <div>


                {
                    (this.props.settings.hasOwnProperty('authRequired') && this.props.settings.authRequired !== true ) ?
                  <div>
                    <MuiThemeProvider>
                      <Navigation />
                      <MovieBrowser  />
                    </MuiThemeProvider>
                    

                     <Footer   settings={{primaryColor: "#000"}}/>
                     </div>
                    : this.props.auth && this.props.auth.hasOwnProperty('sessionId') &&  this.props.auth.sessionId ?  
                      <div>
                      <MuiThemeProvider>
                        <Navigation />
                        <MovieBrowser />
                      </MuiThemeProvider>
                      

                      <Footer settings={{primaryColor: "#000"}} />
                      </div>                    
                    : <Login />
                }


              </div>
            }
          />            
          </Switch>


      </BrowserRouter>

    );
  }
}



const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    auth: state.authReducer.auth, 
    status: state.clipReducer.status,
    settings: state.clipReducer.settings
  }
};


const mapDispatchToProps = (dispatch) => ({
    getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)),
    setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))

});

export default  connect(mapStateToProps, mapDispatchToProps)(App)



