import React, { Component, Fragment, useParams } from 'react';
//import MovieBrowser from './modules/movie-browser/movie-browser.container';
//import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import * as action from './redux/action'
//import { BrowserRouter, Route, Redirect , Link, Switch } from 'react-router-dom';
//import Navigation from './modules/components/Navigation'; 
//import Login from './modules/components/Login/Login'
import Footer from './modules/components/webhtml/Footer'
//import MovieBrowserContainer from './modules/movie-browser/movie-browser.container'
import './i18n'
//import {Button} from 'reactstrap'
//import './template/assets/app.css'
//import './template/assets/animate.css';
import { Player, BigPlayButton } from 'video-react';
//import Jobprofile from './Jobprofile'
//import {Row, Col, Container} from 'react-bootstrap'
import settings from './settings'
import SingleVideoPopup from './modules/components/company/SingleVideoPopup'
import Navigation from './modules/components/Navigation'; 
import { withTranslation } from 'react-i18next';
//import QRCodeResized from './modules/components/company/QRCodeResized';



class CompanyJobProfiles extends Component {

  constructor(props) {
    super(props);
    this.state = {

      jobpage: null
    };


  }


  async componentDidMount(){


    var LangUrlParameterValue = this.getUrlVars()["lang"];

    if (LangUrlParameterValue){
      this.props.i18n.changeLanguage(LangUrlParameterValue)
    }


    await this.getCompanyDetails(this.props.match.params.companyKey, "de")
  }


  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }




  async getCompanyDetails(companyKey, lang){
    console.log('string is: ', settings.api_base_path + settings.portalpath + '/jobpage/' +companyKey+ '?lang=' + lang)
    await fetch(settings.api_base_path + settings.portalpath + '/jobpage/' +companyKey+ '?lang=' + lang, // window.location.hostname, 
    {
        method: 'GET',       
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('data is: ', data)
      this.setState({
        jobpage: data.jobpage
      })

    })    
    .catch((err)=>{

      // this.props.enqueueSnackbar(t('general.dataloadingerror'), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })	  
      console.log('Error in Fetching: ', err)
 
     });    
  }


  render() {

    let localthis = this
    const {t} = this.props; 

 
    return (
      <div>
        

        <Navigation />

  


        {
          this.state.jobpage ? 
<div>

          <div className="hero container-fluid border-bottom">



    <nav className="hero-nav container px-4 px-lg-0 mx-auto">
      <ul className="nav w-100 list-unstyled align-items-center p-0">          

        <li id="hero-menu" className="flex-grow-1 hero__nav-list hero__nav-list--mobile-menu ft-menu">
          <ul className="hero__menu-content nav flex-column flex-lg-row ft-menu__slider animated list-unstyled p-2 p-lg-0">
            <li className="flex-grow-1">
              <ul className="nav nav--lg-side list-unstyled align-items-center p-0">
                <li className="hero-nav__item">
                  <a href="#contacts" className="hero-nav__link">{t('companyjobprofiles.menu.contacts')} </a>
                </li>
                <li className="hero-nav__item">
                  <a href="#jobprofiles" className="hero-nav__link">{t('companyjobprofiles.menu.jobprofile')} </a>
                </li>
                <li class="hero-nav__item">
                  <a href="#companyclip" className="hero-nav__link">{t('companyjobprofiles.menu.companyvideo')}</a>
                </li>
                <li class="hero-nav__item">
                  <a href="#keyfacts" className="hero-nav__link">{t('companyjobprofiles.menu.keyfacts')}</a>
                </li>
                <li class="hero-nav__item">
                  <a href="#whywe" className="hero-nav__link">{t('companyjobprofiles.menu.whywe')}</a>
                </li>
              </ul>
            </li>
          </ul>
          <button onClick={() => document.querySelector('#hero-menu').classList.toggle('ft-menu--js-show')}
            className="ft-menu__close-btn animated">
            <svg className="bi bi-x" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                clip-rule="evenodd" />
              <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                clip-rule="evenodd" />
            </svg>
          </button>
        </li>
        <li className="d-lg-none flex-grow-1 d-flex flex-row-reverse hero-nav__item">
          <button onClick={()=>document.querySelector('#hero-menu').classList.toggle('ft-menu--js-show')}
            class="text-center px-2">
            <i className="fas fa-bars"></i>
          </button>
        </li>
      </ul>
    </nav>


      

          <div className="hero__content container mx-auto">
      <div className="row px-0 mx-0 align-items-center">
        <div id="whywe" class="col-lg-6 px-0">
          <h1 className="hero__title mb-3">
           { t('companyjobprofiles.section1.headerpart1')} <span class="highlight">{ this.state.jobpage.jobCompany.clips[0].title}</span> {t('companyjobprofiles.section1.headerpart2')}
          </h1>
          <p className="hero__paragraph mb-5">
          {this.state.jobpage.jobHeader.page.content}
          </p>
          <div className="hero__btns-container">
            <a className="hero__btn btn btn-primary mb-2 mb-lg-0" href="#companyclip">
              {t('companyjobprofiles.section1.buttonvideocompany')}
            </a>
            <a className="hero__btn btn btn-secondary mx-lg-3" href="#jobprofiles">
              {t('companyjobprofiles.section1.buttonvideosjobprofiles')}
            </a>
          </div>
        </div>
        <div className="col-lg-5 mt-5 mt-lg-0 mx-0">
          <div className="hero__img-container">
            <img src={this.state.jobpage.jobHeader.image} class="hero__img w-100" />
          </div>
        </div>
      </div>
    </div>
  </div>





  <div id="product" className="block-5 space-between-blocks">
    <div className="container">
      
      <div id="keyfacts" className="col-lg-8 col-xl-7 mx-auto text-center mb-5">
        <p className="block__pre-title mb-2">{t('companyjobprofiles.section2.whywe')}</p>
        <h1 className="block__title mb-3"><span className="highlight">{t('companyjobprofiles.section2.keyfacts')}</span></h1>
      </div>
      <div className="row align-items-center flex-column-reverse flex-lg-row px-2">        
        <div className="col-lg-4">
          <div className="card-2 d-flex flex-row flex-lg-row-reverse">
            <div>
              <span className="card-2__symbol mx-auto">
                <i className="fas fa-credit-card"></i>
              </span>
            </div>
            <div className="px-2"></div>
            <div>
              <h3 className="card-2__title mb-2">{t('companyjobprofiles.section2.salary')}</h3>
              <p className="card-2__paragraph">
                {this.state.jobpage.jobKeyfacts.page.keyfactContent1}
              </p>
            </div>
          </div>
          <div className="card-2 d-flex flex-row flex-lg-row-reverse">
            <div>
              <span className="card-2__symbol mx-auto">
                <i className="fas fa-users"></i>
              </span>
            </div>
            <div className="px-2"></div>
            <div>
              <h3 className="card-2__title mb-2">{t('companyjobprofiles.section2.colleagues')}</h3>
              <p className="card-2__paragraph">
              {this.state.jobpage.jobKeyfacts.page.keyfactContent2}
              </p>
            </div>
          </div>
          <div className="card-2 d-flex flex-row flex-lg-row-reverse">
            <div>
              <span className="card-2__symbol mx-auto">
                <i className="fas fa-clock"></i>
              </span>
            </div>
            <div className="px-2"></div>
            <div>
              <h3 className="card-2__title mb-2">{t('companyjobprofiles.section2.workingtime')}</h3>
              <p className="card-2__paragraph">
              {this.state.jobpage.jobKeyfacts.page.keyfactContent3}
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 my-5 text-center">
          <img src={this.state.jobpage.jobKeyfacts.image} class="w-75" />
        </div>        

        <div className="col-lg-4">
          <div className="card-2 d-flex">
            <div>
              <span className="card-2__symbol mx-auto">
                <i className="fas fa-plus-circle"></i>
              </span>
            </div>
            <div className="px-2"></div>
            <div>
              <h3 className="card-2__title mb-2">{t('companyjobprofiles.section2.benefits')}</h3>
              <p className="card-2__paragraph">
              {this.state.jobpage.jobKeyfacts.page.keyfactContent4}
              </p>
            </div>
          </div>
          <div className="card-2 d-flex">
            <div>
              <span className="card-2__symbol mx-auto">
                <i className="fas fa-car"></i>
              </span>
            </div>
            <div className="px-2"></div>
            <div>
              <h3 className="card-2__title mb-2">{t('companyjobprofiles.section2.trafficlinkage')}</h3>
              <p className="card-2__paragraph">
              {this.state.jobpage.jobKeyfacts.page.keyfactContent5} 
              </p>
            </div>
          </div>
          <div className="card-2 d-flex">
            <div>
              <span className="card-2__symbol mx-auto">
                <i className="fas fa-question-circle"></i>
              </span>
            </div>
            <div className="px-2"></div>
            <div>
              <h3 className="card-2__title mb-2">{t('companyjobprofiles.section2.expectations')}</h3>
              <p className="card-2__paragraph">
              {this.state.jobpage.jobKeyfacts.page.keyfactContent6}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div id="companyclip" className="hero__content container mx-auto">
      <div className="row px-0 mx-0 align-items-center">
        <div className="col-lg-6 px-0">
          <h1 className="hero__title mb-3">
            {t('companyjobprofiles.section3.sloganpart1')} <span class="highlight"> {this.state.jobpage.jobCompany.clips[0].title} </span> {t('companyjobprofiles.section3.sloganpart2')}
          </h1>
        </div>
        <div className="col-lg-5 mt-5 mt-lg-0 mx-0">
          <div className="hero__img-container">
            
                <Player playsInline preload={"none"} poster={this.state.jobpage.jobCompany.clips[0].video.videoThumbLow} 
                src={this.state.jobpage.jobCompany.clips[0].video.videoLow} >
                  <BigPlayButton position="center" />
                </Player>


          </div>
        </div>
      </div>
    </div>




    <div id="jobprofiles" className="block-20 space-between-blocks">
    <div className="container">
      <div className="block__header col-lg-8 col-xl-7 mx-auto text-center px-0">
        <h1 className="block__title mb-3">{t('companyjobprofiles.section4.header')}</h1>
        <p className="block__paragraph">
          {t('companyjobprofiles.section4.subline')}
        </p>
      </div>


      <div className="row align-items-center justify-content-center flex-column-reverse flex-lg-row px-2">

        <div className="col-lg-12 col-xl-8 col-md-12">
          <div className="row">
      

                {
                  
                  this.state.jobpage.jobProfiles.clips.map((c, index) => {

                    let startdate = null
                    if (c.clip.publishDate) startdate = new Date(c.clip.publishDate)
                    c.clip.publishDate = startdate ? startdate.getFullYear() + '-' + ("0" + (startdate.getMonth() + 1)).slice(-2) + '-' + ("0" + startdate.getDate()).slice(-2)
                    + ' ' + ("0" + startdate.getHours()).slice(-2) + ":" + ("0" + startdate.getMinutes()).slice(-2)
                    : null                    
                
                    return (

//<div className="col-md-4" style={{clear: (index != 0 && index % 3 == 0) ? 'both' : 'none'}}>
                      <>
                      
                    <SingleVideoPopup className={"col-md-4 mb-2-1rem"} id={c.id} video={c.clip} contact={c.contact.id ? c.contact : null} 
                    >
                                          
</SingleVideoPopup>
                    </>
                    )
                  })
                }

          </div>
          </div>
          </div>


    </div>
  </div>




  <div id="contacts" className="block-28 space-between-blocks border-top border-bottom">
    <div className="container">
      <div className="row h-100">
        <div className="col-lg-6 h-100 ">
          <div className="contact-info">
            <h5 className="contact-info__title mb-3">{t('companyjobprofiles.section5.header')}</h5>
            <p className="contact-info__paragraph mb-5">
              {t('companyjobprofiles.section5.subline')}
            </p>
            <div className="mb-5">
              <h6 className="contact-info__title-2 mb-3">{t('companyjobprofiles.section5.email')}</h6>
              <p className="d-flex flex-column">
              <span className="contact-info__item mb-2">
              <i className="fas fa-envelope"></i><span className="mx-2"><a href={`mailto: ${this.state.jobpage.jobContacts.page.email}`}> {this.state.jobpage.jobContacts.page.email}</a> </span>               
              </span>
              </p>
            </div>
            <div className="mb-5">
              <h6 className="contact-info__title-2 mb-3">{t('companyjobprofiles.section5.phone')}</h6>
              <p className="d-flex flex-column">
                <span className="contact-info__item mb-2">
                  <i className="fas fa-phone"></i><span className="mx-2">{this.state.jobpage.jobContacts.page.phone}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 h-50">
            <div className="contact-form__header mb-5">
              <h6 className="contact-form__title mb-3">{t('companyjobprofiles.section5.videocontacts')}</h6>
              <p className="contact-form__paragraph mb-0 mx-auto">
                {t('companyjobprofiles.section5.videocontacts.sub')}
              </p>
            </div>


            <div className="row align-items-center justify-content-center flex-column-reverse flex-lg-row px-2">

<div className="col-lg-12 col-xl-8">
  <div className="row">
                {
                  this.state.jobpage.jobContacts.clips.map((c,index) => {
                    console.log('ansprechpartner: ', c)
                    return (
                     // <div className="col-md-6" style={{clear: (index != 0 && index % 2 == 0) ? 'both' : 'none'}}>

                                   
                    <SingleVideoPopup className={"col-md-6 mb-2-1rem"} video={c} type="contact"/>

                   
                    //</div>
                    )
                  })
                }

</div>
</div>
</div>

        </div>
      </div>
    </div>
  </div>

</div>

: null
              }


<Footer  settings={{primaryColor: "#000"}} />

      </div>

    );
  }
}



const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    auth: state.authReducer.auth, 
    status: state.clipReducer.status,
    settings: state.clipReducer.settings
  }
};


const mapDispatchToProps = (dispatch) => ({
    getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)),
    setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))

});

export default  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CompanyJobProfiles))



