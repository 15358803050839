import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import './modules/components/webhtml/style/style.css'
import './modules/components/webhtml/style/menu_styles.css'
import './modules/components/webhtml/style/reset.css';
import './modules/components/webhtml/style/superfish.css';
import './modules/components/webhtml/style/prettyPhoto.css';
import './modules/components/webhtml/style/jquery.qtip.css';
import './modules/components/webhtml/style/animations.css';
import './modules/components/webhtml/style/responsive.css';
import './modules/components/webhtml/style/odometer-theme-default.css';
import Navigation from './modules/components/Navigation'
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'

import store, {persistor}  from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import './template.css'

//import { Online, Offline } from 'react-offline-component';






function render()
{ReactDOM.render(

  (
    <Provider store={store}>
  
      <PersistGate loading={null} persistor={persistor}>
  


  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"/>
  {
    /*
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css" />    
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"/>
    */
  }
  
  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap" rel="stylesheet"/>

         <App />
      </PersistGate>
    </Provider>
  

  ),
  document.getElementById('root'));
}
serviceWorker.unregister();
render();
store.subscribe(render);
