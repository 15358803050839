import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as action from '../../../redux/action'
import * as router from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Footer extends Component {

    constructor(props){
      super(props)
    }

    
  render() {

    const {t} = this.props;


    return (
      <div>

  



        <div style={{ backgroundColor: this.props.settings.primaryColor}} className="footer_container">
          <div className="footer clearfix">

            <div className="row copyright_row">
              <div className="column column_2_3">
                © {t('footer.copyright')} <a href={this.props.settings.footerWebUrl} title="Zu unserem Webauftritt" target="_blank">{t('footer.weblink')}</a> - 
                {t('footer.videoportal')} <a href={"mailto:" + this.props.settings.footerMailTo} title="Videoportal Komplettlösung" target="_blank"> {t('footer.contact')} </a>
                <span style={{color: "white", cursor: "pointer"}} onClick={() => this.props.history.push('impressum')}>{t('footer.imprint')} </span>
              </div>

            </div>


            <div style={{marginLeft: "-20px"}}>
              <div className="column column_2_3">
            <ul className="block-44__extra-links d-flex list-unstyled p-0">
            <li className="mx-2">
              <a href="#" className="block-44__link m-0">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li className="mx-2">
              <a href="#" className="block-44__link m-0">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li className="mx-2">
              <a href="#" className="block-44__link m-0">
                <i className="fas fa-envelope"></i>
              </a>
            </li>
          </ul>            
          </div>

</div>


          </div>
        </div>
        <div className="background_overlay"></div>


      </div>
    );
  }
}




const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    advertisingclips: state.clipReducer.advertisingclips,
    settings: state.clipReducer.settings
  }
};


const mapDispatchToProps = (dispatch) => ({
  getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)),
  setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))

});

export default withTranslation()(router.withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer)))

