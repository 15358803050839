import React, { Component } from 'react';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupButton, Fade, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form, FormGroup, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import GroupIcon from '@material-ui/icons/ShortText';
import NavigationIcon from '@material-ui/icons/Navigation';
import TextField from '@material-ui/core/TextField';
import { Badge } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import QRCode from 'qrcode.react'; 

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
    borderRadius: "2px",
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  mybutton: {

    border: "none", 
    outline: "none", 
    borderColor: "transparent", 
    borderWidth: "0px", 
    boxShadow: "none", 
    padding: "0px", 
    background: "none"
      
    
  }
});

class QRCodeResized extends Component {
  constructor(props) {
    super(props);
    this.state = {
        modal: false, 
    };

    this.toggle = this.toggle.bind(this);
  this.closeModal = this.closeModal.bind(this);

  }

  
  toggle(e) {
	    	  	  
    this.setState({
      modal: !this.state.modal
    });
  }

  closeModal(e){

    this.toggle();
   }


  render() {

    const classes = this.props;

    return (
         
        <div>
    {
      /*
          <Fab variant="extended" color={this.props.color} aria-label="Toggle" onClick={this.toggle} className={classes.fab} size="small">
        <GroupIcon className={classes.extendedIcon} />
        {this.props.buttonLabel}
      </Fab>    
      */
    }   
        <button  aria-label="Toggle"  onClick={this.toggle} className={classes.mybutton} >

        <QRCode value={this.props.information} />
      </button>		
		
    
		
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" className={this.props.className}>
    
 
          <ModalHeader toggle={this.toggle}>QRCode für {this.props.information}</ModalHeader> 

        
          <ModalBody>
          <QRCode value={classes.information}  size={this.props.size} level={this.props.level} /> 
          </ModalBody>
          

          <ModalFooter>		                            
			        <Button  color="primary" onClick={this.closeModal}>Schliessen</Button>		                                             
          </ModalFooter>



        </Modal>
      </div>


    );
  }
}

export default withStyles(styles)(QRCodeResized);

