import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as action from '../../../redux/action'
import * as router from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Impressum extends Component {

    constructor(props){
      super(props)
    }

    
  render() {

    const {t} = this.props;


    return (
      <div>

      <h1>Hier folgt das Impressum</h1>
      </div>
    );
  }
}




const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    advertisingclips: state.clipReducer.advertisingclips,
    settings: state.clipReducer.settings
  }
};


const mapDispatchToProps = (dispatch) => ({
  getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)),
  setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))

});

export default withTranslation()(router.withRouter(connect(mapStateToProps, mapDispatchToProps)(Impressum)))

