import React, { Component } from 'react';
import { Player, BigPlayButton } from 'video-react';
import { connect } from 'react-redux';
import * as action from '../../../redux/action'
import { Card, CardBody, CardHeader, CardImg } from "reactstrap"
import Carousel, { autoplayPlugin, slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel';
import '../../components/webhtml/style/mycarouselstyle.css';
import Icon from '@material-ui/icons/ArrowLeft'
import { withStyles } from '@material-ui/styles';



const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  center: {
    margin: '0 auto',
    width: '400px'
  },
  textstyle: {
    border: '1px #000 solid',
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  customBadge: {
    backgroundColor: "red",
    color: "white"
  }
});


class MyCarousel extends Component {

  resizeSurrounding() {
    const width = 640;
    document.querySelector('#surrounding').style.width = `${640}px`;

  }

  render() {
    const { classes } = this.props

    return (
      <div id="surrounding" >
        <Carousel
          arrows
          autoPlay
          infinite
          animationSpeed={1000}
          autoPlay={3000}
          stopAutoPlayOnHover
          slidesPerPage={4}
          slidesPerScroll={1}
          itemWidth={640}
          centered

          breakpoints={{
            1200: { // these props will be applied when screen width is less than 1000px
              itemWidth: 520,
              arrows: true,
            },
            500: {
              itemWidth: 420,
              arrows: false,
            },
          }}

        >
          {
            this.props.advertisingclips && this.props.advertisingclips[0] != null && this.props.advertisingclips.map(singlemovie =>


              <Card body >
                <CardImg top width="100%" />

                <CardHeader style={{ backgroundColor: this.props.settings.primaryColor, color: '#ffffff' }}>
                  {singlemovie.title} | {singlemovie.subtitle}
                </CardHeader>
                <CardBody>
                  <Player
                    playsInline
                    poster={singlemovie.video.videoThumbLow}
                    src={singlemovie.video.video} >
                    <BigPlayButton position="center" />
                  </Player>
                </CardBody>
              </Card>



            )
          }



        </Carousel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    advertisingclips: state.clipReducer.advertisingclips,
    settings: state.clipReducer.settings,
  }
};


const mapDispatchToProps = (dispatch) => ({
  getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyCarousel))
